import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";

const RecrutingPage = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Career",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/kariera/",
      }}
    >
      <section className="page_recrutation">
        <h1>Career</h1>
        <div className="row">
          <div className="col-md-3">
            <ul className="categories">
              <li className="cat-item">
                <Link to="/en/career/account-specialist">
                  Account Specialist
                </Link>
              </li>
              <li className="cat-item">
                <Link to="/en/career/strategy-planner">Strategy Planner</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-8 offset-md-1">
            <div className="panel-description">
              <p>Select an item from the left menu</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default RecrutingPage;
